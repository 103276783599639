import { ComponentProps, FC, createElement } from "react";
import { useHeading } from "./HeadingProvider";

interface Props extends ComponentProps<"h1"> {
  heading?: string;
}

export const Heading: FC<Props> = (props) => {
  const heading = useHeading();
  const tag = `h${props.heading ?? heading}`;

  return createElement(tag, props);
};
