import { createContext, useContext, ReactElement, FC } from "react";

interface Props {
  value?: number;
  children: ReactElement;
}

const context = createContext<number>(null as never);

export function useHeading() {
  return useContext(context);
}

export const HeadingProvider: FC<Props> = ({ value = 1, children }) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};
