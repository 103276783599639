import { ComponentProps, FC } from "react";
import { HeadingProvider, useHeading } from "./HeadingProvider";

export const Section: FC<ComponentProps<"section">> = (props) => {
  const heading = useHeading();

  return (
    <HeadingProvider value={heading + 1}>
      <section {...props} />
    </HeadingProvider>
  );
};
