interface AsPathProps {
  pathname: string;
  hash: string | undefined;
  query?: Record<string, string | number>;
}

export const toPathString = (path: AsPathProps) => {
  let pathString = path.pathname;

  if (path.query) {
    Object.entries(path.query).forEach(([key, value]) => {
      pathString = pathString.replace(`[${key}]`, String(value));
    });
  }

  return pathString;
};
