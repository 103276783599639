import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FC } from "react";
import { Image } from "@/components/Image/Image";
import { pagesPath } from "@/lib/$path";
import { toPathString } from "@/lib/toPathString";

interface Props {
  isArticle?: boolean;
}

export const Footer: FC<Props> = ({ isArticle }) => {
  const pathname = usePathname();
  const isWebMedia = pathname && pathname.startsWith("/kdotdev");

  return (
    <footer
      className={clsx(
        "flex flex-col gap-4 bg-black !pt-[16px] text-xs text-white",
        isWebMedia ? "kdotdev-container !pb-[32px]" : "kdot-container !pb-[16px]",
        isArticle && "article-container",
      )}
    >
      <div className="flex items-center justify-between">
        <div>
          <span>© KDOT, inc.</span>
        </div>
        <Link href={pagesPath.$url()} className="flex items-end" target="_blank">
          <Image width={24} height={24} className="h-[24px] w-[24px]" src={"/images/logo/inline-small.svg"} alt="" />
          <span className="ml-2 text-sm font-medium">株式会社KDOT</span>
        </Link>
      </div>

      {isWebMedia && (
        <a
          href={toPathString(pagesPath.kdotdev.feed.$url())}
          className={clsx("flex h-[24px] w-[24px] items-center justify-center rounded-full bg-white")}
          target="_blank"
        >
          <Image width={20} height={20} className="h-[20px] w-[20px]" src={"/images/rss.svg"} alt="" />
        </a>
      )}
    </footer>
  );
};
