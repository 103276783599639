export function preventScroll() {
  document.documentElement.style.overflowY = "hidden";
  document.body.style.overflow = "hidden";
  document.body.style.height = "100vh";
}

export function allowScroll() {
  document.documentElement.style.overflowY = "scroll";
  document.body.style.overflow = "scroll";
  document.body.style.height = "auto";
}
