import { ComponentProps, FC } from "react";
import { useIsMobile } from "../useWindowSize";
import { PCHeader } from "./PCHeader/PCHeader";
import { SPHeader } from "./SPHeader/SPHeader";

interface Props extends Omit<ComponentProps<"div">, "children"> {
  isArticle?: boolean;
}

export const Header: FC<Props> = ({ isArticle, ...props }) => {
  const isMobile = useIsMobile();

  return <div {...props}>{isMobile ? <SPHeader /> : <PCHeader isArticle={isArticle} />}</div>;
};
