import clsx from "clsx";
import { FC } from "react";

interface Props {
  open: boolean;
}

export const Hamburger: FC<Props> = ({ open }) => {
  return (
    <div className="h-[32px] w-[32px]">
      <div
        className={clsx(
          "mx-0 my-2.5 h-px w-8 transition-[0.25s] duration-[transform]",
          open ? "translate-y-[11px] rotate-45 bg-white" : "bg-black",
        )}
      ></div>
      <div
        className={clsx(
          "mx-0 my-2.5 h-px w-8 bg-black transition-[0.25s] duration-[opacity]",
          open ? "bg-white opacity-0" : "bg-black",
        )}
      ></div>
      <div
        className={clsx(
          "mx-0 my-2.5 h-px w-8 bg-black transition-[0.25s] duration-[transform]",
          open ? "translate-y-[-11px] -rotate-45 bg-white" : "bg-black",
        )}
      ></div>
    </div>
  );
};
