import { CSSProperties, FC, useRef } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import { Image } from "@/components/Image/Image";
import { pagesPath } from "@/lib/$path";
import { ListItem } from "../ListItem/ListItem";

interface Props {
  open: boolean;
  handleLinkClick: () => void;
}

export const Menu: FC<Props> = ({ open, handleLinkClick }) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  const transitionProperties = {
    entered: { opacity: 1 },
    entering: { opacity: 1 },
    exited: { opaicty: 0 },
    exiting: { opacity: 0 },
    unmounted: {},
  } as const;

  return (
    <TransitionGroup>
      {open ? (
        <Transition key={"menu"} nodeRef={nodeRef} timeout={250} mountOnEnter>
          {(state) => {
            return (
              <div
                ref={nodeRef}
                className="absolute inset-0 h-screen w-screen"
                style={{
                  transition: ".25s",
                  opacity: 0,
                  ...(transitionProperties[state] as CSSProperties),
                }}
              >
                <div className="absolute inset-0 bg-primary" />
                <Image
                  src="/images/home/front.png"
                  alt=""
                  className="absolute top-[40%] h-[60vh] w-full object-cover object-right-top mix-blend-multiply"
                  width={2560}
                  height={1536}
                />
                <nav className="relative h-screen w-screen px-[10%] py-[150px]">
                  <ul>
                    <ListItem href={pagesPath.$url({ hash: "top" })} onClick={handleLinkClick}>
                      トップ
                    </ListItem>
                    <ListItem href={pagesPath.$url({ hash: "services" })} onClick={handleLinkClick}>
                      サービス
                    </ListItem>
                    <ListItem href={pagesPath.$url({ hash: "company" })} onClick={handleLinkClick}>
                      会社情報
                    </ListItem>
                    <ListItem href={pagesPath.inquiry.$url({ hash: "" })} onClick={handleLinkClick}>
                      お問合せ
                    </ListItem>
                  </ul>
                </nav>
              </div>
            );
          }}
        </Transition>
      ) : (
        <></>
      )}
    </TransitionGroup>
  );
};
