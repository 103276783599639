import NextImage, { ImageProps } from "next/image";
import { forwardRef } from "react";

export let Image = NextImage;

const NomalImage = forwardRef<HTMLImageElement, ImageProps>(function Image({ src, ...props }, ref) {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
    <img ref={ref} src={typeof src == "string" ? src : "src" in src ? src.src : src.default.src} {...props} />
  );
}) as typeof NextImage;

/**
 * For usage from .storybook/preview.ts so that images can be normal img tag
 * inside Storybook.
 *
 *     import { normalImageForStorybook } from '@/atoms/Image/Image'
 *     normalImageForStorybook()
 */
export function normalImageForStorybook() {
  Image = NomalImage;
}
