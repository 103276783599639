import clsx from "clsx";
import Link from "next/link";
import { FC, useState } from "react";
import { Image } from "@/components/Image/Image";
import { allowScroll, preventScroll } from "@/lib/preventScroll";
import { Heading } from "../../Html/Heading";
import { Hamburger } from "./Hamburger/Hamburger";
import { Menu } from "./Menu/Menu";

export const SPHeader: FC = () => {
  const [open, setOpen] = useState(false);
  const handleHamburgerClick = () => {
    if (!open) {
      setOpen(true);
      preventScroll();
    } else {
      setOpen(false);
      allowScroll();
    }
  };
  const handleLinkClick = () => {
    setOpen(false);
    allowScroll();
  };

  return (
    <header className={clsx("kdot-container block md:hidden")}>
      <Menu handleLinkClick={handleLinkClick} open={open}></Menu>
      <div className="relative flex items-center justify-between py-[10px]">
        <Heading className="leading-[0]">
          <Link href="/" className="relative">
            <Image
              src="/images/logo/normal.png"
              alt="kdot Logo"
              priority
              width={191}
              height={34}
              className="inline h-[34px] w-auto"
            />
            <Image
              src="/images/logo/white.png"
              alt="kdot Logo"
              width={191}
              height={34}
              className="absolute left-0 inline h-[34px] w-auto"
              style={{ transition: ".25s", opacity: open ? 1 : 0 }}
            />
          </Link>
        </Heading>
        <button onClick={handleHamburgerClick} aria-label="menu">
          <Hamburger open={open} />
        </button>
      </div>
    </header>
  );
};
