import Link from "next/link";
import { ReactNode, MouseEventHandler, FC } from "react";
import { UrlObject } from "url";

interface ListItemProps {
  href: UrlObject;
  children: ReactNode;
  target?: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
}

export const ListItem: FC<ListItemProps> = ({ href, children, target, onClick }) => {
  return (
    <li className="border-b-[1px] border-white px-0 py-[15px] text-white last-of-type:border-b-0">
      <Link href={href} className="flex justify-between text-[3rem]" onClick={onClick} target={target}>
        {children}
      </Link>
    </li>
  );
};
