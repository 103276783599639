import clsx from "clsx";
import Link from "next/link";
import { FC, MouseEventHandler, ReactNode } from "react";
import { UrlObject } from "url";

interface Props {
  href: UrlObject;
  children: ReactNode;
  hash: string;
  target?: string;

  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const ListItem: FC<Props> = ({ href, children, hash, onClick, target }) => {
  return (
    <li className="border-y-[2px] border-black px-0 py-[5px]">
      <Link
        onClick={onClick}
        href={href}
        className={clsx("block", hash == href.hash && "text-primary")}
        target={target}
      >
        {children}
      </Link>
    </li>
  );
};
