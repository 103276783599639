export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "contact_sent": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact_sent' as const, hash: url?.hash })
  },
  "embed": {
    "github": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/embed/github' as const, hash: url?.hash })
    }
  },
  "inquiry": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/inquiry' as const, hash: url?.hash })
  },
  "kdotdev": {
    _slug: (slug: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/[slug]' as const, query: { slug }, hash: url?.hash })
    }),
    "author": {
      _name: (name: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/author/[name]' as const, query: { name }, hash: url?.hash })
      })
    },
    "feed": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/feed' as const, hash: url?.hash })
    },
    "pages": {
      _page_number: (page_number: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/pages/[page_number]' as const, query: { page_number }, hash: url?.hash })
      })
    },
    "rss": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/rss' as const, hash: url?.hash })
    },
    "tag": {
      _slug: (slug: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/tag/[slug]' as const, query: { slug }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/kdotdev/tag' as const, hash: url?.hash })
    }
  },
  "news": {
    _slug: (slug: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/news/[slug]' as const, query: { slug }, hash: url?.hash })
    })
  },
  "privacy": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/privacy' as const, hash: url?.hash })
  },
  "server_sitemap_xml": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/server-sitemap.xml' as const, hash: url?.hash })
  },
  "service": {
    "data_analysis": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/service/data-analysis' as const, hash: url?.hash })
    },
    "dev_advisor_general": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/service/dev-advisor-general' as const, hash: url?.hash })
    },
    "dev_advisor_professional": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/service/dev-advisor-professional' as const, hash: url?.hash })
    },
    "dev_project_startup_support": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/service/dev-project-startup-support' as const, hash: url?.hash })
    },
    "web_system_dev": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/service/web-system-dev' as const, hash: url?.hash })
    }
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;
