import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { Image } from "@/components/Image/Image";
import { pagesPath } from "@/lib/$path";
import { Heading } from "../../Html/Heading";
import { ListItem } from "./ListItem/ListItem";

interface Props {
  isArticle?: boolean;
}

export const PCHeader: FC<Props> = ({ isArticle }) => {
  const ref = useRef<HTMLElement>(null);
  const router = useRouter();
  const [hash, setHash] = useState("");
  useEffect(() => {
    setHash(router.asPath.split("#")[1]);
  }, [router]);
  const handleLinkClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    // トップページ以外の場合はnext/routerによる遷移を行う
    if (router.pathname !== "/") {
      await router.push(e.currentTarget.href);

      return;
    }
    const id = e.currentTarget.href.split("#")[1];
    // window.location.hashの変更はnext/routerが変更されないのでsetHashを明示的に呼び出す
    setHash(id);
    // ↓これによってスクロールが発生する
    window.location.hash = id;
  };

  return (
    <header
      className={clsx(
        "kdot-container hidden items-center justify-between !py-[20px] text-black md:flex",
        isArticle && "article-container",
      )}
      ref={ref}
    >
      <Heading className="leading-[0]">
        <Link href="/">
          <Image
            src="/images/logo/normal.png"
            alt="kdot Logo"
            priority
            width={191}
            height={217}
            style={{ height: 34, width: "auto" }}
          />
        </Link>
      </Heading>
      <nav>
        <ul className="grid grid-cols-[100px,100px,100px,100px] grid-rows-[1fr] gap-[20px] lg:grid-cols-[150px,150px,150px,150px]">
          <ListItem href={pagesPath.$url({ hash: "top" })} onClick={(event) => void handleLinkClick(event)} hash={hash}>
            トップ
          </ListItem>
          <ListItem
            href={pagesPath.$url({ hash: "services" })}
            onClick={(event) => void handleLinkClick(event)}
            hash={hash}
          >
            サービス
          </ListItem>
          <ListItem
            href={pagesPath.$url({ hash: "company" })}
            onClick={(event) => void handleLinkClick(event)}
            hash={hash}
          >
            会社情報
          </ListItem>
          <ListItem href={pagesPath.inquiry.$url({ hash: "" })} hash={hash}>
            お問合せ
          </ListItem>
        </ul>
      </nav>
    </header>
  );
};
